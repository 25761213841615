.layout {
  background-color: @self-bg-body;
}

.img-logo {
  width: 179.97px;
  height: 46px;
}

.btn-head {
  z-index: 30;
  background-color: #ffffff00;
  /* trdhz-content-r */
  font-family: 'Kanit'; 
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  padding: 12px 35px;
  margin-left: 56px;
  height: 51px;

  text-align: center;
  color: #FFFFFF;
  height: 51px;
  border-radius: 30px;

  &:hover {
    // background-color: @self-bg-body;
    background: #D55A0F;
  }
}


.dust-bg {
  position: absolute;
  top: 600px;
  height: 2586px;
  width: 1440px;
  margin: auto;

  // background: rgba(136, 80, 227, 0.17);
  background-repeat: no-repeat;
  background-size: cover;
  // filter: blur(476px);
  z-index: 1;
}

.footer {
  margin-top: 67px;
  justify-content: center;
  height: 251px;
  position: relative;

  .image {
    position: absolute;
    height: 251px;
    bottom: 0px;
    width: 900.899169921875px;
  }
  .component {
    z-index: 100;
  }
}