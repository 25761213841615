@import '~antd/dist/antd.less';
@import './variables.less';
@import './colors.less';

@import './home/_home.less';
@import './ant/_form.less';


@import './grid/_grid.less';

@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;1,200;1,300&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Kanit', sans-serif;
  background-color: @self-bg-body;
  color: white;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
