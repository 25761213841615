@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v12/nKKV-Go6G5tXcraQI2GAdQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v12/nKKS-Go6G5tXcraQI82hVaQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v12/nKKS-Go6G5tXcraQI6miVaQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v12/nKKU-Go6G5tXcr5aOiWg.ttf) format('truetype');
}
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v12/nKKU-Go6G5tXcr4-OSWg.ttf) format('truetype');
}
