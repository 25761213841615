.Main {
  max-height: 944px;
  height: 944px;

  #bg-effect-header {
    .Ellipse38 {
      z-index: 18;
      position: absolute;
      width: 854px; // 1440-1055=889
      display: table;
      margin: 0 auto;
      height: 834px;
      right: 0;
      background-repeat: no-repeat;
      top: -149px; //204+150
    }

    .Ellipse39 {
      position: absolute;
      width: 531px;
      height: 541px;
      left: 0px;
      top: 266px;
      z-index: 20;

      border-radius: 50%;
      background: linear-gradient(310.45deg, rgba(255, 255, 255, 0.13) 10.53%, rgba(255, 255, 255, 0) 57.22%);
      mix-blend-mode: difference;
    }
  }

  .head-desc {
    z-index: 30;

    .about-btn {
      //text
      color: white;
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height */

      text-align: center;

      /* TEXT */

      color: #FFFFFF;


      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 10px;

      /* เกี่ยวกับเรา */
      box-sizing: border-box;
      /* Auto layout */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 54px;

      position: absolute;
      width: 198px;
      height: 54px;
      left: 181.22px;
      top: 371px;

      background: rgba(78, 54, 10, 0.5);
      mix-blend-mode: multiply;
      border: 2px solid #EE9900;
      border-radius: 30px;

      &:hover {
        font-weight: 500;
        /* identical to box height */

        text-align: center;

        /* TEXT */

        color: #FFFFFF;

        text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;

        background: linear-gradient(99.5deg, #ED9E10 9%, #FF8A00 83.37%);
        border: 2px solid #ee6b00;
        border-radius: 30px;
      }
    }

    .desc {
      position: absolute;
      width: 517px;
      height: 60px;
      left: 191px;
      top: 447px;

      font-family: 'Kanit';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;

      color: #FFFFFF;
    }
  }

  .qrcode {
    z-index: 30;

    .image {
      position: absolute;
      width: 82px;
      height: 82px;
      top: 603px;
      right: 180.72px;
      /* Inside auto layout */
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 15px;
    }

    .text {
      /* ติดต่อสอบถาม และดูผลงาน โดยการสแกน QR code */
      position: absolute;
      width: 227px;
      height: 60px;
      right: 277.72px;
      top: 614px;

      font-family: 'Kanit';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: right;

      color: rgba(255, 255, 255, 0.63);


      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 15px;
    }
  }

  .glass {
    .company-name {
      .trz01 {
        z-index: 30;
        position: absolute;
        width: 700px;
        height: 108px;
        left: 584.22px;
        top: 149px; //447-298=149

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 72px;
        line-height: 108px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.12em;

        color: #FFFFFF;

        text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.39);
      }

      .trz02 {
        z-index: 30;
        position: absolute;
        width: 700px;
        height: 108px;
        left: 584.22px;
        top: 149px;


        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 72px;
        line-height: 108px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.12em;

        color: rgba(255, 255, 255, 0.5);

        filter: blur(10px);
      }

      .trz03 {
        z-index: 30;
        position: absolute;
        width: 700px;
        height: 108px;
        left: 584.22px;
        top: 149px;


        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 72px;
        line-height: 108px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.12em;

        color: rgba(255, 162, 0, 0.85);

        filter: blur(10px)
      }

      .Line3 {
        z-index: 30;
        position: absolute;
        width: 1002px;
        height: 0px;
        left: 439.22px;
        top: 262px;
        border-radius: 25px;

        border: 3px solid rgba(255, 255, 255, 0.26);
        box-shadow: 0px 0px 21px rgba(255, 255, 255, 0.41);
      }

      .Line2 {
        z-index: 30;
        position: absolute;
        width: 1002px;
        height: 0px;
        left: 439.22px;
        top: 262px;
        border-radius: 25px;


        border: 3px solid rgba(255, 255, 255, 0.54);
        box-shadow: 0px 0px 21px rgba(255, 255, 255, 0.41);
      }
    }

    .Ractangle {
      z-index: 20;
      position: absolute;
      width: 1309px;
      height: 740px;
      left: 66.22px;
      top: 1px;

      mix-blend-mode: overlay;
      // border-radius: 30px;
    }

    .Ractangle120 {
      z-index: 19;
      position: absolute;
      width: 1319px;
      height: 746px;
      left: 61.22px;
      top: -4px;

      // background: linear-gradient(111.02deg, rgba(255, 255, 255, 0.216) 0%, rgba(255, 255, 255, 0.027) 85.19%);
      backdrop-filter: blur(20px);
      /* Note: backdrop-filter has minimal browser support */

      // border-radius: 30px;
    }

    .Ractangle129 {
      z-index: 21;
      position: absolute;
      width: 327px;
      height: 316px;
      left: 522px;
      top: 247px;


      background: #8850E3;
      filter: blur(378px);
      border-radius: 30px;
    }

    .Ractangle121 {
      z-index: 17;
      width: 1440px;
      height: 559px;
      position: absolute;
      left: 0px;
      top: 353px;

      background: linear-gradient(180deg, rgba(21, 31, 41, 0) 0%, #151F29 58.33%);
    }

    .Ellipse37 {
      z-index: 16;
      position: absolute;
      width: 900px;
      height: 909px;
      right: 0px;
      top: -149px; //302.74+150
    }

    .Ractangle122 {
      z-index: 15;
      position: absolute;
      width: 1440px;
      height: 776px;
      left: 0px;
      top: -1.84px;


      background: linear-gradient(180deg, rgba(219, 123, 47, 0) 36.77%, rgba(219, 123, 47, 0.58) 89.24%, #362110 109.23%);
    }



  }
}