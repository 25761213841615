.ProgrammingTech {
  background-color: #ffffff;
  margin-top: 98px;
  padding-bottom: 62px;

  .title {
    margin-top: 110px;
    margin-bottom: 85px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */

    text-align: right;
    letter-spacing: 0.13em;
    text-transform: uppercase;

    color: #151F29;
  }
  
}