// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
@import '~antd/lib/style/themes/default.less';
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,200;0,300;1,100;1,200;1,300&display=swap');

@font-family: 'Kanit', sans-serif;

@theme: dark;
@primary-color: #5D5FEF;
@form-bg-color: #F7F7FA;
// @text-color: #FFFFFF;
//
//@table-bg: "red";           <-- 'red' is not a valid css value.
//@table-header-bg: "blue";

@table-bg: red;
@table-header-bg: blue;

@layout-header-background: @white;
@layout-header-height: auto;
@layout-header-padding: 0px;
@layout-header-color: @text-color;


@btn-border-radius-base: 4px;

// Badge
// ---
@badge-color: @primary-color;
.ant-badge {
  .ant-badge-dot {
    background: @primary-color;
  }
}

// Select
// ---
@select-background: @form-bg-color;

