@spacer: 1rem;

.m {
  &-0 {
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
  }
  &-1 {
    margin-top: (@spacer * .25) !important;
    margin-left: (@spacer * .25) !important;
    margin-bottom: (@spacer * .25) !important;
    margin-right: (@spacer * .25) !important;
  }
  &-2 {
    margin-top: (@spacer * .5) !important;
    margin-left: (@spacer * .5) !important;
    margin-bottom: (@spacer * .5) !important;
    margin-right: (@spacer * .5) !important;
  }
  &-3 {
    margin-top: (@spacer) !important;
    margin-left: (@spacer) !important;
    margin-bottom: (@spacer) !important;
    margin-right: (@spacer) !important;
  }
  &-4 {
    margin-top: (@spacer * 1.5) !important;
    margin-left: (@spacer * 1.5) !important;
    margin-bottom: (@spacer * 1.5) !important;
    margin-right: (@spacer * 1.5) !important;
  }
  &-5 {
    margin-top: (@spacer * 3) !important;
    margin-left: (@spacer * 3) !important;
    margin-bottom: (@spacer * 3) !important;
    margin-right: (@spacer * 3) !important;
  }
}

.mt {
  &-0 {
    margin-top: 0 !important;
  }
  &-1 {
    margin-top: (@spacer * .25) !important;
  }
  &-2 {
    margin-top: (@spacer * .5) !important;
  }
  &-3 {
    margin-top: (@spacer) !important;
  }
  &-4 {
    margin-top: (@spacer * 1.5) !important;
  }
  &-5 {
    margin-top: (@spacer * 3) !important;
  }
}

.mb {
  &-0 {
    margin-bottom: 0 !important;
  }
  &-1 {
    margin-bottom: (@spacer * .25) !important;
  }
  &-2 {
    margin-bottom: (@spacer * .5) !important;
  }
  &-3 {
    margin-bottom: (@spacer) !important;
  }
  &-4 {
    margin-bottom: (@spacer * 1.5) !important;
  }
  &-5 {
    margin-bottom: (@spacer * 3) !important;
  }
}

.ml {
  &-0 {
    margin-left: 0 !important;
  }
  &-1 {
    margin-left: (@spacer * .25) !important;
  }
  &-2 {
    margin-left: (@spacer * .5) !important;
  }
  &-3 {
    margin-left: (@spacer) !important;
  }
  &-4 {
    margin-left: (@spacer * 1.5) !important;
  }
  &-5 {
    margin-left: (@spacer * 3) !important;
  }
}

.mr {
  &-0 {
    margin-right: 0 !important;
  }
  &-1 {
    margin-right: (@spacer * .25) !important;
  }
  &-2 {
    margin-right: (@spacer * .5) !important;
  }
  &-3 {
    margin-right: (@spacer) !important;
  }
  &-4 {
    margin-right: (@spacer * 1.5) !important;
  }
  &-5 {
    margin-right: (@spacer * 3) !important;
  }
}

.mz {
  &-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &-1 {
    margin-top: (@spacer * .25) !important;
    margin-bottom: (@spacer * .25) !important;
  }
  &-2 {
    margin-top: (@spacer * .5) !important;
    margin-bottom: (@spacer * .5) !important;
  }
  &-3 {
    margin-top: (@spacer) !important;
    margin-bottom: (@spacer) !important;
  }
  &-4 {
    margin-top: (@spacer * 1.5) !important;
    margin-bottom: (@spacer * 1.5) !important;
  }
  &-5 {
    margin-top: (@spacer * 3) !important;
    margin-bottom: (@spacer * 3) !important;
  }
}

.mx {
  &-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  &-1 {
    margin-left: (@spacer * .25) !important;
    margin-right: (@spacer * .25) !important;
  }
  &-2 {
    margin-left: (@spacer * .5) !important;
    margin-right: (@spacer * .5) !important;
  }
  &-3 {
    margin-left: (@spacer) !important;
    margin-right: (@spacer) !important;
  }
  &-4 {
    margin-left: (@spacer * 1.5) !important;
    margin-right: (@spacer * 1.5) !important;
  }
  &-5 {
    margin-left: (@spacer * 3) !important;
    margin-right: (@spacer * 3) !important;
  }
}


.p {
  &-0 {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
  }
  &-1 {
    padding-top: (@spacer * .25) !important;
    padding-left: (@spacer * .25) !important;
    padding-bottom: (@spacer * .25) !important;
    padding-right: (@spacer * .25) !important;
  }
  &-2 {
    padding-top: (@spacer * .5) !important;
    padding-left: (@spacer * .5) !important;
    padding-bottom: (@spacer * .5) !important;
    padding-right: (@spacer * .5) !important;
  }
  &-3 {
    padding-top: (@spacer) !important;
    padding-left: (@spacer) !important;
    padding-bottom: (@spacer) !important;
    padding-right: (@spacer) !important;
  }
  &-4 {
    padding-top: (@spacer * 1.5) !important;
    padding-left: (@spacer * 1.5) !important;
    padding-bottom: (@spacer * 1.5) !important;
    padding-right: (@spacer * 1.5) !important;
  }
  &-5 {
    padding-top: (@spacer * 3) !important;
    padding-left: (@spacer * 3) !important;
    padding-bottom: (@spacer * 3) !important;
    padding-right: (@spacer * 3) !important;
  }
}

.pt {
  &-0 {
    padding-top: 0 !important;
  }
  &-1 {
    padding-top: (@spacer * .25) !important;
  }
  &-2 {
    padding-top: (@spacer * .5) !important;
  }
  &-3 {
    padding-top: (@spacer) !important;
  }
  &-4 {
    padding-top: (@spacer * 1.5) !important;
  }
  &-5 {
    padding-top: (@spacer * 3) !important;
  }
}

.pb {
  &-0 {
    padding-bottom: 0 !important;
  }
  &-1 {
    padding-bottom: (@spacer * .25) !important;
  }
  &-2 {
    padding-bottom: (@spacer * .5) !important;
  }
  &-3 {
    padding-bottom: (@spacer) !important;
  }
  &-4 {
    padding-bottom: (@spacer * 1.5) !important;
  }
  &-5 {
    padding-bottom: (@spacer * 3) !important;
  }
}

.pl {
  &-0 {
    padding-left: 0 !important;
  }
  &-1 {
    padding-left: (@spacer * .25) !important;
  }
  &-2 {
    padding-left: (@spacer * .5) !important;
  }
  &-3 {
    padding-left: (@spacer) !important;
  }
  &-4 {
    padding-left: (@spacer * 1.5) !important;
  }
  &-5 {
    padding-left: (@spacer * 3) !important;
  }
}

.pr {
  &-0 {
    padding-right: 0 !important;
  }
  &-1 {
    padding-right: (@spacer * .25) !important;
  }
  &-2 {
    padding-right: (@spacer * .5) !important;
  }
  &-3 {
    padding-right: (@spacer) !important;
  }
  &-4 {
    padding-right: (@spacer * 1.5) !important;
  }
  &-5 {
    padding-right: (@spacer * 3) !important;
  }
}

.pz {
  &-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  &-1 {
    padding-top: (@spacer * .25) !important;
    padding-bottom: (@spacer * .25) !important;
  }
  &-2 {
    padding-top: (@spacer * .5) !important;
    padding-bottom: (@spacer * .5) !important;
  }
  &-3 {
    padding-top: (@spacer) !important;
    padding-bottom: (@spacer) !important;
  }
  &-4 {
    padding-top: (@spacer * 1.5) !important;
    padding-bottom: (@spacer * 1.5) !important;
  }
  &-5 {
    padding-top: (@spacer * 3) !important;
    padding-bottom: (@spacer * 3) !important;
  }
}

.px {
  &-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  &-1 {
    padding-left: (@spacer * .25) !important;
    padding-right: (@spacer * .25) !important;
  }
  &-2 {
    padding-left: (@spacer * .5) !important;
    padding-right: (@spacer * .5) !important;
  }
  &-3 {
    padding-left: (@spacer) !important;
    padding-right: (@spacer) !important;
  }
  &-4 {
    padding-left: (@spacer * 1.5) !important;
    padding-right: (@spacer * 1.5) !important;
  }
  &-5 {
    padding-left: (@spacer * 3) !important;
    padding-right: (@spacer * 3) !important;
  }
}

.w {
  &-100 {
    width: 100%;
  }
  &-50 {
    width: 50%;
  }
}

.h {
  &-100 {
    height: 100%;
  }
  &-50 {
    height: 50%;
  }
}