.Target {
  position: relative;
  margin-top: 41.37px;

  .head-title {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
    /* identical to box height */


    /* gradienthead */

    background: linear-gradient(180deg, #E77623 0%, #FFA800 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    /* head shadow */

    text-shadow: 0px 0px 8px rgba(238, 153, 0, 0.5);
  }

  .desc {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    color: #FFFFFF;
  }

  .card {
    margin-top: 27px;

    box-sizing: border-box;

    height: 400px;

    background: rgba(40, 54, 74, .45);
    background-blend-mode: soft-light;
    border: 3px solid rgba(65, 86, 110, 1);
    border-radius: 30px;

    .icon {
      width: 130px;
      height: 130px;
    }

    .main {
      margin: 47px 35px 30px 30px;
    }

    .main2 {
      margin: 47px 35px 30px 60px;
    }

    .title {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 22px;
      /* identical to box height, or 92% */

      text-align: center;

      /* Character / Primary(inverse) */

      color: #FFFFFF;


      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 25px 0px;
    }

    .desc {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* or 138% */

      text-align: center;

      /* Character / Primary(inverse) */

      color: #FFFFFF;


      /* Inside auto layout */

      flex: none;
      order: 2;
      flex-grow: 0;

      z-index: 3;
    }

    .image {
      width: 475px;
      height: 345px;

      margin: 5px 0px 0px 32px;

      background-size: cover;
      border: 1px solid #000000;
      box-shadow: 0px 0px 20px 20px #3A3154;

      filter: drop-shadow(6px 6px 16px rgba(0, 0, 0, 0.5));
    }

    .image2 {
      width: 475px;
      height: 345px;

      margin: 0px 0px 0px 30px;

      background-size: cover;
      border: 1px solid #000000;
      box-shadow: 0px 0px 20px 20px #3A3154;

      filter: drop-shadow(0px 0px 50px #3C3962);
    }
  }
}

.trzBg {
  position: absolute;
  top: 224.37px;
  width: 1440px;
  height: 641.06px;
}