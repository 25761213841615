.Design {
  margin-top: 75px;
  position: relative;

  flex: auto;

  .card {
    position: relative;
    width: 660px;
    height: 181px;

    background: rgba(46, 55, 77, .45);
    background-blend-mode: soft-light;
    border: 3px solid rgba(77, 88, 111, 1);

    border-radius: 30px;
    // padding-top: 25px;
    padding: 11px 0px 11px 19px;

    .icon {
      width: 130px;
      height: 130px;
      // margin: 25px 20px auto 19px;
    }

    .text {
      font-family: 'Kanit';
      font-style: normal;
      color: #FFFFFF;
      flex: none;

      flex-grow: 0;
      margin: 30px 0px;

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 22px;
        /* identical to box height, or 92% */

        order: 0;

        margin-bottom: 30px;
      }

      .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        /* Inside auto layout */

        order: 1;
      }

      span {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        /* or 110% */


        color: #7765FF;
      }
    }
  }

  .main-design {
    .title {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 60px;
      /* identical to box height */

      text-align: right;

      /* gradienthead */

      background: linear-gradient(180deg, #E77623 0%, #FFA800 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;

      /* head shadow */

      text-shadow: 0px 0px 8px rgba(238, 153, 0, 0.5);
    }

    .desc {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: right;

      color: #FFFFFF;

      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: right;

        color: #7665FF;
      }
    }
  }

  .contact-us-container {
    position: relative;
    margin-top: 81px;

    .contact-us {
      height: 168px;
      backdrop-filter: blur(72px);
      background: rgba(21, 31, 41, 0.99);
      mix-blend-mode: soft-light;
      z-index: 1;
    }

    .circle {
      position: absolute;
      width: 193px;
      height: 193px;
      border-radius: 50%;
      z-index: -1;
      right: 315px;
      top: -73px;

      background: linear-gradient(231.95deg, #FFA325 37.6%, #EB7321 54.04%, #0C2037 81.58%);
    }
  }

  .contact-us-click {
    position: absolute;
    top: 0px;
    width: 1440px;

    .title {
      mix-blend-mode: normal;
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      text-align: center;

      color: #FFFFFF;
    }

    .btn {
      width: 228px;
      height: 54px;

      span {
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height */

        text-align: center;

        /* TEXT */

        color: #FFFFFF;
        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
      }


      background: rgba(237, 158, 16, 0.25);
      background-blend-mode: multiply;
      border: 2px solid #EE9900;
      border-radius: 30px;
      padding: 12px 54px;

      &:hover {
        span {
          font-family: 'Kanit';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          /* identical to box height */

          text-align: center;

          /* TEXT */

          color: #FFFFFF;

          text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);

          /* Inside auto layout */

          flex: none;
          order: 0;
          flex-grow: 0;
        }

        background: linear-gradient(99.5deg, #ED9E10 9%, #FF8A00 83.37%);
        border: 2px solid #EE9900;
        border-radius: 30px;
      }
    }
  }
}