.InstallService {
  height: 921px;
  position: relative;

  // background-color: #ffffff00;

  // z-index: 3;

  .circle01 {
    position: absolute;
    width: 236px;
    height: 236px;
    right: 120.78px;
    top: -32px;

    background: rgba(98, 81, 240, 0.15);
    mix-blend-mode: multiply;
    filter: blur(191px);
    border-radius: 50%;
  }

  .card {
    box-sizing: border-box;

    height: 400px;

    background: #30384e73;
    border: 3px solid #475571;
    border-radius: 30px;
    mix-blend-mode: overlay;

    .icon {
      width: 130px;
      height: 130px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-top: 47px;
      margin-bottom: 25px;
      z-index: 20;
    }

    .title {
      width: 300px;
      height: 44px;

      font-family: 'Kanit';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 22px;
      /* or 92% */

      text-align: center;

      /* Character / Primary(inverse) */

      color: #FFFFFF;


      /* Inside auto layout */
      flex: none;
      order: 1;
      flex-grow: 0;
      margin-bottom: 25px;
    }

    .desc {
      width: 300px;
      height: 66px;

      font-family: 'Kanit';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* or 138% */

      text-align: center;

      /* Character / Primary(inverse) */

      color: #FFFFFF;


      /* Inside auto layout */

      flex: none;
      order: 2;
      flex-grow: 0;
    }
  }

  .head-title {
    background-color: #ffffff00;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
    /* identical to box height */


    /* gradienthead */

    background: linear-gradient(180deg, #E77623 0%, #FFA800 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    /* head shadow */

    text-shadow: 0px 0px 8px rgba(238, 153, 0, 0.5);
  }

  .head-desc {
    // background-color: #ffffff00;
    width: 823px;
    margin-top: 12px;

    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    color: #FFFFFF;

    span {
      font-style: normal;
      font-weight: 600;

      /* CI/Purple */

      color: #7665FF;
    }
  }


  .contact-us-container {
    position: relative;
    margin-top: 111px;

    .contact-us {
      height: 168px;
      backdrop-filter: blur(72px);
      background: rgba(21, 31, 41, 0.99);
      mix-blend-mode: soft-light;
      z-index: 1;
    }

    .circle {
      position: absolute;
      width: 193px;
      height: 193px;
      border-radius: 50%;
      z-index: -1;
      left: 287px;
      top: -73px;

      background: linear-gradient(231.95deg, #FFA325 37.6%, #EB7321 54.04%, #0C2037 81.58%);
    }
  }

  .contact-us-click {
    position: absolute;
    top: 0px;
    width: 1440px;

    .title {
      mix-blend-mode: normal;
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      text-align: center;

      color: #FFFFFF;
    }

    .btn {
      width: 228px;
      height: 54px;

      span {
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height */

        text-align: center;

        /* TEXT */

        color: #FFFFFF;
        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
      }


      background: rgba(237, 158, 16, 0.25);
      background-blend-mode: multiply;
      border: 2px solid #EE9900;
      border-radius: 30px;
      padding: 12px 54px;

      &:hover {
        span {
          font-family: 'Kanit';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          /* identical to box height */

          text-align: center;

          /* TEXT */

          color: #FFFFFF;

          text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);

          /* Inside auto layout */

          flex: none;
          order: 0;
          flex-grow: 0;
        }

        background: linear-gradient(99.5deg, #ED9E10 9%, #FF8A00 83.37%);
        border: 2px solid #EE9900;
        border-radius: 30px;
      }
    }
  }

}

.InstallService-tooltip {
  margin: 20px;
  font-family: 'Kanit';
  font-style: normal;

  /* or 122% */


  /* Character / Primary(inverse) */

  color: #FFFFFF;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  .desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}